<!-- 语法 -->
<template>
    <div class="">
        <pl35header></pl35header>
    </div> 
    <router-view />
    <smFooter></smFooter>
    <Footer></Footer>
</template>

<script>
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
import pl35header from '@/components/pl35/pl35-header.vue'
import smFooter from '@/components/common/exp.vue'
import  Footer from '@/components/Footer.vue'
export default {
name: '',
components: {
    pl35header,
    smFooter,
    Footer
},
data(){ // 数据源
    return{
    } 
},
mounted() {

},
computed:{
},
methods:{ // 函数体部分，js的主要逻辑控制               
},
props: {
}
}
</script>

<style scoped>
     /* @import './swiper.css'; */
</style>